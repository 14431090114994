var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-row', [_c('b-col', {
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('b-card', {
    attrs: {
      "title": "Common"
    }
  }, [_c('b-card-text', [_vm._v("No ability is required to view this card")]), _c('b-card-text', {
    staticClass: "text-primary"
  }, [_vm._v(" This card is visible to 'user' and 'admin' both ")])], 1)], 1), _vm.$can('read', 'Analytics') ? _c('b-col', {
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('b-card', {
    attrs: {
      "title": "Analytics"
    }
  }, [_c('b-card-text', [_vm._v("User with 'Analytics' subject's 'Read' ability can view this card")]), _c('b-card-text', {
    staticClass: "text-danger"
  }, [_vm._v(" This card is visible to 'admin' only ")])], 1)], 1) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }